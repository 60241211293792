import PropTypes from "prop-types";
import React, { createContext } from "react";
import { Form } from 'react-final-form';
import { email, maxLength, required, url } from "../FormField/rules";
import { FORM_ERROR } from 'final-form';

export const YooContext = createContext()

export const withYoo = Component => props => (
  <YooContext.Consumer>
    {store => <Component {...props} {...store} />}
  </YooContext.Consumer>
)

const sortOptions = (options) => {
  return options.sort(function (a, b) {
    let textA = a.label.toUpperCase();
    let textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
};

const convertFieldFormat = (fields, langs, parentfieldname = "") => {
  const newFields = {}
  const { selectedLang } = langs

  Object.keys(fields).map(e => fields[e]).forEach(field => {
    const newField = {}

    newField["validate"] = [];

    Object.keys(field).forEach(function (key, index) {
      switch (key) {
        case "title": newField["label"] = field.title;
          if (selectedLang && field.type !== 'object') {
            newField["label"] = field.title_trads[selectedLang];
          }
          break
        case "key":
          newField["name"] = parentfieldname ? parentfieldname + "." + field.key : field.key;
          break
        case "is_mandatory":
          newField["required"] = !!field.is_mandatory;
          if (newField["required"]) {
            newField["validate"].push(required);
          }
          break
        case "type":
          switch (field.type) {
            case "image": newField["type"] = "image"; break
            case "file": newField["type"] = "file"; break
            case "data-title": newField["type"] = "text"; break
            case "select-data": newField["type"] = "select"; break
            case "select-data-multiple":
            case "selector-multiple":
              newField["type"] = "select";
              newField["multiple"] = true;
              break;
            case "selector": newField["type"] = "select"; break
            case "textbox": newField["type"] = "text"; newField["debounced"] = true; break;
            case "textarea": newField["type"] = "textarea"; newField["debounced"] = true; break
            case "object":
              newField["type"] = "object";
              newField["fields"] = convertFieldFormat(field["fields"], langs, field["key"])
              break;
            case "email":
              newField["type"] = "text";
              newField["validate"].push(email);
              break
            case "link":
              newField["type"] = "text";
              newField["validate"].push(url);
              break
            default:
          }
          break
        case "values":
          if (field.values.length > 0) {
            const options = field.values.map(field => {
              return {
                value: field.key,
                label: field.label
              }
            })

            newField["options"] = options;
            const arrayMode = ["radiobox-horizontal", "radiobox"];
            const mode = field.config['mode'];

            if (!arrayMode.includes(mode)) {
              sortOptions(options);
            }

          }
          break


        /* eslint-disable */
        case "config":
          Object.keys(field.config).map(key => {
            switch (key) {
              case 'extensions':
                newField['allowed_extensions'] = '.' + field.config[key].join(',.')
                break
              case 'single':
                newField['multiple'] = !field.config[key]
                break
              case 'ratio':
                newField['ratio'] = field.config[key]
                break
              case 'maxlength':
                newField['limit'] = field.config[key] ? parseInt(field.config[key], 10) : null
                newField['maxLength'] = field.config[key];
                break
              case 'placeholder_trads':
                if (selectedLang) {
                  newField['placeholder'] = field.config[key][selectedLang];
                }
                break
              case 'mode':
                if (field.config[key] === 'radiobox' || field.config[key] === 'radiobox-horizontal') {
                  newField['type'] = 'radiobox'
                }
                break
            }
          })
          break
        /* eslint-enable */

        case "limit":
          newField["validate"].push(maxLength(parseInt(field.limit, 10)));
          newField["limit"] = parseInt(field.limit, 10);
          break

        case "iddata_type": break;
        case "dataType": break;
        case "fields": break;
        case "datatypeUik": break;

        default:
          newField[key] = field[key]; break
      }

    })

    newFields[field.key] = newField
  })

  return newFields
}

class YooForm extends React.Component {

  state = {
    recaptchaCode: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    captchaValid: false,
    dataType: '',
    fields: {},
    langs: {},
    disableSubmit: false,
    stateFiles: {},
    loading: true,
    uikchallenge: ''
  }


  checkCaptcha = async (value) => {
    var url = 'api.php?task=verify';
    var formData = new FormData();

    formData.append("captcha", value);

    const response = await fetch(url, { method: 'POST', body: formData })
    const result = await response.json();

    if (result) {
      this.setState({ captchaValid: true });
    }
  }

  checkIconCaptcha = async (value) => {
    if (value === true) {
      this.setState({ captchaValid: true });
    }
  }

  disableSubmit = (options) => {
    const { filenames, deleteFiles } = options
    const { stateFiles } = this.state

    let newFiles = stateFiles

    /* eslint-disable */
    if (deleteFiles) {
      filenames && Object.keys(filenames).map((key) => {
        newFiles[filenames[key]] && delete newFiles[filenames[key]]
      })
    } else {
      filenames && Object.keys(filenames).map((key) => {
        newFiles[filenames[key]] = true
      })
    }
    /* eslint-enable */

    this.setState({ disableSubmit: Object.keys(newFiles).length > 0, stateFiles: newFiles })
  }

  sendFile = async (dataType, name, filename, id) => {
    var url = 'api.php?task=sendFile';
    var formData = new FormData();

    if (Array.isArray(filename)) {
      filename = filename.join(",");
    }

    formData.append("dataType", dataType);
    formData.append("fieldname", name);
    formData.append("filename", filename);
    formData.append("id", id);

    await fetch(url, { method: 'POST', body: formData })
  }

  bringtoTop = () => {
    setTimeout(() => {
      const el = document.querySelector('.error')
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    }, 200);
    return true;
  }

  sendEmail = async (fields) => {
    const url = 'api.php?task=mail';
    let formData = new FormData();
    let fieldsList = [];
    const stateFields = this.state.fields;

    Object.keys(fields).forEach(i => {
      let field = fields[i];
      const { key } = field;

      let { title, placeholder, label, isMail } = stateFields[key];
      if (Object.keys(stateFields).indexOf(key) > -1) {
        if (!label) {
          label = title || placeholder || key;
        }
      }

      field.label = label;
      field.isMail = isMail;
      fieldsList.push(field);
    })

    formData.append('fields', JSON.stringify(fieldsList));

    const response = await fetch(url, { method: 'POST', body: formData });

    return response;
  }

  submitData = async (dataType, fields, values) => {
    const result = {};
    const errors = {};

    var url = 'api.php?task=saveData';
    var formData = new FormData();

    formData.append("dataType", dataType);

    const filefields = Object.keys(fields).map(e => fields[e]).filter(field => (field.type === "image" || field.type === "file") ? true : false)
      .map(field => field.name);

    // Only send form values other than file fields and accept rules field
    for (var key in values) {
      if ([...filefields, "rules", "rules2"].indexOf(key) === -1) {
        if (fields[key]["type"] === "object") {
          formData.append(key, values[key]["id"]);
        }
        else if (fields[key]["type"] === "select" && Array.isArray(values[key])) {
          for (var i = 0; i < values[key].length; i++) {
            formData.append(key + '[]', [values[key][i]]);
          }
          if (fields[key].isDynamic) {
            const { dynValue } = fields[key]
            let idsArray = []

            /* eslint-disable-next-line */
            values[key].map((e) => {
              const id = dynValue[e]
              if (idsArray.indexOf(id) === -1) {
                idsArray.push(id)
              }
            })
            /* eslint-disable-next-line */
            idsArray.map((e) => {
              formData.append(fields[key].isDynamic.field + '[]', e)
            })
          }
        }
        else {
          formData.append(key, values[key]);
        }
      }
    }

    const response = await fetch(url, { method: 'POST', body: formData })
    const json = await response.json();

    if (!json) {
      alert("Erreur Serveur");
      return result;
    }

    if (json.errors) {
      for (var key2 in json.errors) {
        if (json.errors[key2]["value"]) {
          errors[key2] = json.errors[key2]["value"][0]
        }
        else if (json.errors[key2]["value.0"]) {
          errors[key2] = json.errors[key2]["value.0"]
        }
        else if (Array.isArray(json.errors[key2])) {
          errors[key2] = json.errors[key2][0]
        }
        if (key2 === 'title') {
          for (const key3 in fields) {
            if (fields[key3].isTitle) {
              errors[key3] = errors.title
            }
          }
        }
      }
      this.bringtoTop();

      result.errors = errors;

      return result;

    } else {
      if (!json.data || !json.data.id) {
        alert("Impossible de récupérer l'id du formulaire")
        return result;
      }
      const id = json.data.id;
      const { data } = json;

      const fileValues = Object.keys(values).filter(name => filefields.indexOf(name) !== -1 ? true : false);

      for (let index = 0; index < fileValues.length; index++) {
        await this.sendFile(dataType, fileValues[index], values[fileValues[index]], id)
      }
      result.id = id;
      result.data = data || {};

      return result;
    }
  }

  onSubmit = async values => {
    const { CAPTCHA_INVALID } = this.state.langs;

    if (!this.state.captchaValid) {
      return { [FORM_ERROR]: CAPTCHA_INVALID }
    }

    const listKeys = Object.keys(this.state.fields);

    for (let index = 0; index < listKeys.length; index++) {
      const field = this.state.fields[listKeys[index]];

      if (field.type === "object") {
        if (!values[field.name].id) {
          for (const subField in field.fields) {
            if (values[subField] && values[subField].id) {
              values[field.name][subField] = values[subField].id
            }
          }
          // Save contact
          const result = await this.submitData(field.data_type, field.fields, values[field.name]);

          if (result.errors) {
            return result.errors;
          }

          values[field.name].id = result.id;
        }
      }
    }

    // Save data
    const result = await this.submitData(this.state.dataType, this.state.fields, values);

    if (result.id) {
      let { data: { fields } } = result;
      // Send email
      await this.sendEmail(fields);
      window.location = './success.php'
    } else {
      return result.errors;
    }
  };

  componentDidMount() {

    fetch('api.php?task=getConfig')
      .then(response => response.json())
      .then(data => {
        window.langs = data.langs
        return this.setState({
          fields: convertFieldFormat(data.fields, data.langs),
          recaptchaCode: data.captchaCode,
          dataType: data.dataType,
          langs: data.langs,
          stateFiles: {},
          loading: false,
          uikchallenge: data.uikChallenge
        })
      });
  }

  render() {
    const { loadingComponent, formid } = this.props
    const { loading, uikchallenge, ...state } = this.state
    const initialValues = {}
    if (uikchallenge) {
      initialValues[uikchallenge] = formid
    }
    const store = {
      ...state,
      checkCaptcha: this.checkCaptcha,
      checkIconCaptcha: this.checkIconCaptcha,
      disableSubmit: this.disableSubmit
    }

    return (
      <YooContext.Provider value={store} >
        {loading ?
          loadingComponent
          :
          <Form
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            render={(props) => (
              <form onSubmit={props.handleSubmit} noValidate>
                {this.state.dataType && this.props.render({ ...props, fields: this.state.fields, langs: this.state.langs, bringtoTop: this.bringtoTop, disableSubmit: this.state.disableSubmit })}
              </form>
            )}
          />
        }
      </YooContext.Provider>
    )
  }
}

YooForm.propTypes = {
  render: PropTypes.func.isRequired
}

export default YooForm

import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Spinner from 'react-spinkit';
import FinalField from '../../../lib/FinalField';
import YooField from "../../../lib/YooField";
import YooForm from '../../../lib/YooForm';
import SvgApply from './icons/SvgApply';
import SvgDownload from '../../../lib/icons/SvgDownload';
import FormSkeleton from './FormSkeleton';
import YooCaptchaIcon from '../../../lib/YooCaptchaIcon';
import YooCaptcha from '../../../lib/YooCaptcha';


class App extends Component {
  handleSubmit = (event) => {
    console.log('handleSubmit', event)
  }

  handleCaptcha = (langs) => {
    return langs.isNewCaptcha ? <YooCaptchaIcon /> : <YooCaptcha />;
  }

  /* eslint-disable */
  getTerms = (langs) => {
    return (
      <span>
        {langs.ACCEPT}
        <a className='accept' target="_blank" href={langs.TERMSLINK}>
          {langs.TERMS}
        </a>
      </span>
    )
  }
  /* eslint-enable */


  loadingSkeleton() {
    return <FormSkeleton />;
  }

  render() {
    const color1 = "#554CF5";
    const color2 = "#212122";
    const textColor = "#939191"
    const bgColor = "#F2F2F2"
    const phColor = "#6c757d"

    const selectStyles = {
      control: (styles, { isFocused }) => {
        return {
          ...styles,
          border: 'none',
          backgroundColor: isFocused
            ? 'white'
            : bgColor,
          padding: '5px 2px',
          borderRadius: '7px',
          boxShadow: isFocused
            ? `0 0 0 0.15rem ${color1}`
            : 'none',
          marginBottom: 0,
          ':hover': {
            ...styles[':hover'],
            boxShadow: `0 0 0 0.15rem ${color1}`
          }
        }
      },
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? color1 : isFocused ? color1 : null,
          padding: "2px 10px",
          color: isDisabled
            ? '#ccc'
            : isSelected
              ? 'white'
              : isFocused
                ? 'white'
                : textColor,
          cursor: isDisabled ? 'not-allowed' : 'pointer',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? color1 : null),
          },
        };
      },
      placeholder: (styles) => {
        return {
          ...styles,
          color: phColor
        }
      },
      multiValue: (styles) => {
        return {
          ...styles,
          backgroundColor: color2,
          borderRadius: '6px'
        };
      },
      multiValueLabel: (styles) => ({
        ...styles,
        color: 'white',
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px'
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        color: 'white',
        ':hover': {
          backgroundColor: "#C90035",
          color: 'white',
          borderTopRightRadius: '6px',
          borderBottomRightRadius: '6px'
        },
      }),
    }


    return (
      <YooForm
        loadingComponent={this.loadingSkeleton()}
        render={({ submitError, submitting, fields, langs, bringtoTop, disableSubmit }) => (
          <div className="sl-form">
            {submitting && <div className="overlay">
              <Spinner name="pulse" color={color1} />
              <div className="loader-text">{langs.loaderText}</div>
            </div>
            }
            <div className="wow zoomIn" data-wow-delay="0.3s">
              <div className="form-title">
                <SvgApply color1={color1} />
                <h2>{langs.formTitle}</h2>
              </div>
              <hr />
              <p>{langs.formTitleSubtitle}</p>
            </div>


            <div className="group-startup">
              <Container>
                <h2 className="section-title wow zoomIn" data-wow-delay="0.3s">{langs.infosStartup}</h2>
                <Row className="idcard">
                  <Col md={3} className="wow fadeInLeft">
                    <YooField field="application-picture" className="logo-container" />
                  </Col>
                  <Col md={9}>
                    <YooField field="application-title" className="wow zoomIn" />
                    <YooField field="application-theme" className="wow zoomIn" styles={selectStyles} />
                    <YooField field="application-links" className="wow zoomIn" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="wow fadeInUp" >
                    <YooField field="application-address" />
                  </Col>
                  <Col md={12} className="wow fadeInUp" >
                    <YooField field="application-country" styles={selectStyles} />
                  </Col>
                  <Col md={12} className="wow fadeInUp" >
                    <YooField field="application-workforce" />
                  </Col>
                  <Col md={12} className="wow fadeInUp" >
                    <YooField field="application-creationdate" />
                  </Col>
                  <Col md={12} className="wow fadeInUp" >
                    <YooField field="application-description" />
                  </Col>
                  <Col md={12} className="wow fadeInUp" >
                    <YooField field="application-refrence" />
                  </Col>
                  <Col md={12} className="wow fadeInUp" >
                    <YooField field="application-files" />
                  </Col>
                </Row>
                <h2 className="section-title wow zoomIn" data-wow-delay="0.3s">{langs.contactStartup}</h2>
                <Row>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-contacts.contact-firstname" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-contacts.contact-lastname" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-contacts.contact-email" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-contacts.contact-phone" />
                  </Col>
                </Row>
                <h2 className="section-title wow zoomIn" data-wow-delay="0.3s">{langs.projectStartup}</h2>
                <Row>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    <YooField field="application-projectdescription" />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    {/* Revoir le lien du PDF pour le reglèment */}
                    <FinalField
                      langs={langs}
                      label={this.getTerms(langs)}
                      name="rules"
                      type="checkbox"
                      required
                    />
                  </Col>
                  <Col md={12} className="wow zoomIn" data-wow-delay="0.3">
                    {this.handleCaptcha(langs)}
                    {submitError &&
                      <div className="helpblock">
                        {submitError}
                      </div>}

                    {fields && (
                      <div className="buttons">
                        <Button
                          type="submit"
                          disabled={submitting || disableSubmit}
                          onClick={bringtoTop}
                          className="btn-style button-action button-action-red"
                          size="lg"
                          block
                        >
                          {disableSubmit ? langs.sendingFiles : langs.submitText}
                          <SvgDownload className="pictoSvg" />
                        </Button>

                      </div>
                    )}
                  </Col>
                </Row>
              </Container>

            </div>
          </div>
        )
        } />
    );
  }
}

export default App;

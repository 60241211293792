import PropTypes from "prop-types";
import React from "react";
import FinalField from "../FinalField";
import { withYoo } from "../YooForm";

class YooField extends React.Component {

  getField = (field) => {
    if (field.indexOf(".") !== -1) {
      const paths = field.split(".");
      return this.props.fields[paths[0]]["fields"][paths[1]];
    } else {
      return this.props.fields[field];
    }
  }

  render() {

    const { field, fields, langs, ...rest } = this.props

    delete rest.checkCaptcha
    delete rest.checkIconCaptcha
    delete rest.disableSubmit
    delete rest.recaptchaCode
    delete rest.captchaValid
    delete rest.dataType
    delete rest.stateFiles
    delete rest.visible

    const fieldObj = this.getField(field);

    if (fieldObj) {

      const attrs = { ...fieldObj }

      delete attrs.options
      delete attrs.isTitle

      let values = [];

      if (fieldObj.options) {
        values = fieldObj.options.map((option, index) => {
          return <option key={index} value={option.value} legend={option.legend} image={option.image}>{option.label}</option>
        })
      }

      if (attrs.type === "select") {
        attrs.noResultText = langs.noResult;
      }

      if (attrs.type === "image") {
        attrs.editionImg = langs.editionImg;
        attrs.cancel = langs.cancel;
        attrs.save = langs.save;
      }

      if (attrs.type === "file") {
        attrs.tooBigMsg = langs.tooBigMsg;
      }

      return (
        <FinalField
          {...attrs}
          {...rest}
          langs={langs}
        >
          {values}
        </FinalField>

      )
    } else {
      return null
    }
  }
}

YooField.propTypes = {
  field: PropTypes.string,
  fields: PropTypes.object,
  langs: PropTypes.object
}

export default withYoo(YooField)

/* eslint max-statements:0 */
/* eslint max-depth:0 */
import PropTypes from "prop-types";
import React from "react";
import RCSelect, { components } from "react-select";
import CreatableSelect from "react-select/lib/Creatable";

class Select extends React.Component {

  constructor(props) {

    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleCreateOption = this.handleCreateOption.bind(this)
    const { options, value, creatable } = props

    if (creatable && options && options.indexOf(value) < 0) {
      this.state = {
        createdOptions: [value]
      }
    } else {
      this.state = {
        createdOptions: []
      }
    }
  }

  handleCreateOption(inputValue) {
    const { onCreateOption, onChange, multiple } = this.props
    let { value } = this.props

    if (onCreateOption) {
      onCreateOption(inputValue)
    } else {
      const { createdOptions } = this.state

      createdOptions.push(inputValue)
      this.setState({ createdOptions })

      if (multiple) {
        if (!value) {
          value = []
        }
        value.push(inputValue)
        onChange(value)
      } else {
        onChange(inputValue)
      }
    }
  }

  handleChange(val) {
    const { onChange, ordered } = this.props

    if (!onChange) return

    let value

    if (!val) {
      value = val
    } else if (Array.isArray(val)) {
      value = val.map(item => item.value)

      if (ordered) {
        value = this.normalizeOptions()
          .map(item => item.value)
          .filter(optValue => value.includes(optValue))
      }

    } else {
      value = val.value
    }

    if (this.props.multiple && !Array.isArray(value)) {
      value = [value];
    }

    onChange(value)
  }

  handleBlur() {

    const { onBlur, value } = this.props

    if (onBlur) onBlur(value)
  }

  normalizeOptions() {

    const { children, grouped, creatable } = this.props
    const { createdOptions } = this.state
    let { options } = this.props

    if (grouped) {
      return options
    }

    if (options || (creatable && createdOptions)) {
      if (options) {
        options = [...options, ...createdOptions]
      } else {
        options = createdOptions
      }

      return options.map(option => {

        if (option.value == null) {

          if (option.label == null) {
            if (typeof option === "object") {
              return option
            }

            return { value: option, label: option }
          } else {
            return { value: option.label, label: option.label, disabled: Boolean(option.disabled) }
          }

        } else {

          if (option.label == null) option.label = option.value

          return option
        }
      })

    } else {

      return React.Children.map(children, ({ props }) => {

        let label

        if (props.label) {
          label = props.label
        } else {
          label = props.children
        }

        return {
          value: props.value == null ? props.children : props.value,
          label,
          disabled: Boolean(props.disabled)
        }

      })
    }

  }

  normalizeValue(options) {
    const { value, multiple, grouped } = this.props

    if (!value) return value
    let selectedOptions

    if (multiple && Array.isArray(value)) {
      selectedOptions = options.filter(opt => value.includes(opt.value))
    } else {
      const allOptions = grouped ? options.flatMap(group => group.options) : options

      selectedOptions = allOptions.find(opt => opt.value === value)
    }

    return selectedOptions
  }

  formatMessage = (input) => {
    return input // (intl.formatMessage(messages.createValue, { value : input }))
  }

  render() {

    const { intl, placeholder, readOnly, disabled, value, multiple, clearable, noResultText, creatable, required, ...rest } = this.props

    let options = this.normalizeOptions()

    const selectedOption = this.normalizeValue(options)

    if (disabled && readOnly) { return null }

    if (readOnly) {

      let textContent

      if (multiple) {

        textContent = options
          .filter(opt => value.indexOf(opt.value) !== -1)
          .map(opt => opt.label)
          .join(", ")

      } else {

        textContent = selectedOption && selectedOption.label
      }

      return (
        <div style={{ padding: "7px 0", minHeight: 34 }}>
          {textContent}
        </div>
      )
    }

    delete rest.onBlur
    delete rest.onChange
    delete rest.options
    delete rest.ordered
    delete rest.multiple
    delete rest.onCreateOption

    const Construct = creatable ? CreatableSelect : RCSelect

    let isClearable = true

    if (clearable !== undefined) {
      isClearable = clearable
    } else if (required) {
      isClearable = false
    }

    if (options == null) {
      options = [{ value: "", label: "", disabled: true }]
    }


    return (
      <Construct
        options={options}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={selectedOption}
        components={{
          NoOptionsMessage: (props) => <components.NoOptionsMessage {...props} children={noResultText} />
        }}
        formatCreateLabel={this.formatMessage}
        placeholder={placeholder}
        isDisabled={disabled}
        isMulti={multiple}
        closeMenuOnSelect={!multiple}
        isClearable={isClearable}
        onCreateOption={this.handleCreateOption}
        classNamePrefix="juloa-select"
        {...rest}
      />
    )
  }

}

Select.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  children: PropTypes.node,
  options: PropTypes.array,
  ordered: PropTypes.bool,
  grouped: PropTypes.bool,
  style: PropTypes.object,
  creatable: PropTypes.bool,
  required: PropTypes.bool
}

export default Select

import React from "react"
import PropTypes from "prop-types"

const Custom = ({ value, options, name, onChange, multi, style, disabled, ...rest }) => {

  delete rest.onBlur
  delete rest.bsStyle

  return (
    <div style={{padding: '7px 0'}} className="form-check-images">
      { options.map((opt, i) => {


        function handleChange(selectedValue) {
          onChange(selectedValue)
        }

        const checked = (opt.value === value)

        return (

          <div key={i} className={ "juloa-default-group form-check-image " + (checked ? "checked" : "") }
            onClick={() => { handleChange(opt.value)}}>
            <div>
              <span className="graphic-checkbox"></span>
              <img src={ opt.image } alt={ opt.label }/>
              <label type="checkbox" className="form-check-label">{ opt.label }</label>
            </div> 
            <p className="form-check-legend">
              { opt.legend }
            </p>
          </div>

          
        )

      }) }
    </div>
  )
}

Custom.propTypes = {
  name : PropTypes.string,
  onChange : PropTypes.func,
  label : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  multi : PropTypes.bool,
  style : PropTypes.object,
  options : PropTypes.array,
  disabled : PropTypes.bool
}

export default Custom

/* global IconCaptcha */

import React, { useCallback, useEffect, useState } from 'react';
import { withYoo } from "../YooForm";

const YooCaptchaIcon = ({ checkIconCaptcha, langs }) => {
  const [captchaHtml, setCaptchaHtml] = useState(null);
  const { locale, captchaWidth: maxWidth } = langs;

  const myInitCode = useCallback(() => {
    if (IconCaptcha) {
      IconCaptcha.init('.iconcaptcha-widget', {
        general: {
          endpoint: 'api.php?task=captcha-verify',
          fontFamily: 'inherit',
          credits: 'show',
        },
        security: {
          interactionDelay: 1500,
          hoverProtection: true,
          displayInitialMessage: true,
          initializationDelay: 500,
          incorrectSelectionResetDelay: 3000,
          loadingAnimationDuration: 1000,
        },
        locale,
      }).bind('success', function(e) {
          checkIconCaptcha && checkIconCaptcha(true);
        }).bind('error', function(e) {
          checkIconCaptcha && checkIconCaptcha(false);
        });
    }
  },[checkIconCaptcha]);

  useEffect(() => {
      const fetchCaptchaHtml = async () => {
          try {
              const response = await fetch('api.php?task=captcha-token')
              if (!response.ok) {
                  throw new Error(`Error: ${response.statusText}`);
              }
              const html = await response.text();
              setCaptchaHtml(html);
          } catch (err) {
              console.log('🚀 ~ fetchCaptchaHtml ~ err:', err);
          }
      };

      fetchCaptchaHtml();
  }, []);

  useEffect(() => {
    if (document.readyState !== 'loading') {
      console.log('document is already ready, just execute code here');
      myInitCode();
    } else {
      document.addEventListener('DOMContentLoaded', function () {
        console.log('document was not ready, place code here');
        myInitCode();
      });
    }
  }, []);

  return (
      <div>
        <div
          className="iconcaptcha-widget"
          data-theme="light"
          style={{ maxWidth: `${maxWidth}px` }}
        ></div>
          {captchaHtml && (
            <div dangerouslySetInnerHTML={{ __html: captchaHtml }} />
          )}
      </div>
  );
};

export default withYoo(YooCaptchaIcon);
import React from "react"
import PropTypes from "prop-types"
import Classic from "./Classic"
import Custom from "./Custom"
import Chance from "chance"

const chance = new Chance()

const types = {
  readOnly : PropTypes.bool,
  children : PropTypes.node,
  value : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  multi : PropTypes.bool,
  display : PropTypes.oneOf(["default", "custom"]),
  options : PropTypes.array,
  name : PropTypes.string
}

class Group extends React.Component {

  static propTypes = types

  constructor(props) {

    super(props)

    this.defaultName = chance.guid()
  }

  normalizeOptions() {

    const { children, options } = this.props

    if (options) {

      return options.map(option => {

        if (option.value == null) {

          if (option.label == null) {
            if ((typeof option) === "object") {
              return option
            }

            return { value : option, label : option }
          } else {
            return { value : option.label, label : option.label, disabled : Boolean(option.disabled) }
          }

        } else {

          if (option.label == null) option.label = option.value

          return option
        }
      })

    } else {

      return React.Children.map(children, ({ props }) => {

        return {
          value : props.value || props.children,
          label : props.children,
          legend : props.legend,
          image : props.image,
          disabled : Boolean(props.disabled)
        }
      })
    }

  }

  getValue() {

    const { value, multi } = this.props

    if (multi && !Array.isArray(value)) return value ? [value] : []
    else if (!multi && Array.isArray(value)) return value[0]
    else return value
  }

  render() {

    const { readOnly, display, multi, name, ...rest } = this.props

    let options = this.normalizeOptions()
    if (options == null) {
      options = [{ value:"",label:"",disabled:true}]
    }

    const currentValue = this.getValue()

    for (const n in types) delete rest[n]

    if (readOnly) {

      let textContent

      if (multi) {

        textContent = options
          .filter(opt => currentValue.indexOf(opt.value) !== -1)
          .map(opt => opt.label)
          .join(", ")

      } else {

        const selected = options.find(opt => opt.value === currentValue)

        textContent = selected && selected.label
      }

      return (
        <div { ...rest } >
          { textContent }
        </div>
      )

    } else {

      const Construct = (display === "custom") ? Custom : Classic

      return (
        <Construct
          { ...rest }
          name={ name || this.defaultName }
          multi={ multi }
          value={ currentValue }
          options={ options }
        />
      )

    }
  }

}

export default Group

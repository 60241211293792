/* eslint max-statements:0 */
import PropTypes from "prop-types";
import React from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";

/* 
  <GroupField>
    <FormField type="date"/>
  </GroupField>
*/

const GroupField = ({
  level,
  inline,
  message,
  label,
  labelWidth,
  intl,
  style,
  children,
  className,
  bsSize,
  type,
  required,
  readOnly,
  help,
  ...rest
}) => {
  if (rest.value === undefined) {
    rest.value = null
  }
  const { langs: { disableTitles } } = rest
  const withTitles = disableTitles !== "true"
  return (
    <FormGroup
      style={{ ...(inline ? { display: "flex", alignItems: "center" } : null), ...style }}
      className={(className ? className : "") + " " + (level ? level : "")}
    >
      {label && withTitles ? (
        <FormLabel
          style={{
            ...(inline ? { width: labelWidth } : null),
            ...(message ? { alignSelf: "flex-start" } : null)
          }}
        >
          {label}
          {required && !readOnly ? <span style={{ color: "#EC5F78" }}> *</span> : ""}
        </FormLabel >
      ) : null}
      <div className="form-group-container" style={{ position: "relative", ...(inline ? { flex: 1, position: "relative" } : null) }}>
        {children}
        {message && [
          <FormControl.Feedback key="feedback" />,
          <span key="helpblock" className="helpblock" >{message}</span>
        ]}
      </div>
    </FormGroup>
  )
}

GroupField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  level: PropTypes.oneOf(["error", "warning", "success"]),
  message: PropTypes.oneOfType([PropTypes.string]),
  inline: PropTypes.bool,
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  bsSize: PropTypes.string,
  type: PropTypes.any,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  help: PropTypes.string
}

GroupField.defaultProps = {
  labelWidth: "150px"
}

export default GroupField



import PropTypes from "prop-types";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withYoo } from "../YooForm";

class YooCaptcha extends React.Component {

  render() {
    return (
      <ReCAPTCHA
        sitekey={this.props.recaptchaCode}
        onChange={this.props.checkCaptcha}
      />
    )
  }
}

YooCaptcha.propTypes = {
  checkCaptcha: PropTypes.func.isRequired,
  recaptchaCode: PropTypes.string.isRequired
}

export default withYoo(YooCaptcha)

import React from "react"
import PropTypes from "prop-types"

const Counter = (props) => {

  const { characters, value, limit } = props;

  return (
    <span
      style={ { color : Number(value.length) > limit ? "#EC5F78" : "#aaaaaa" } }
      className="counter"
    >
      { value.length + "/" + limit + ' ' + characters}
      
    </span>
  )

}

Counter.propTypes = {
  value : PropTypes.string,
  limit : PropTypes.number
}

export default function createCounterText(Component) {

  const CounterText = (props) => {

    const { value, limit, characters, ...rest } = props
    

    return (
      <div>
        { limit && typeof value === "string" ? <Counter value={ value } limit={ limit } characters={ characters } /> : ""}
        <Component
          value={ value }
          { ...rest }
        />
        
      </div>
    )


  }

  CounterText.propTypes = {
    value : PropTypes.string,
    limit : PropTypes.number
  }

  CounterText.displayName = "CounterText(" + Component.displayName + ")"

  return CounterText

}

/* eslint-disable complexity */
import PropTypes from "prop-types";
import React from "react";
import { FormControl } from "react-bootstrap";
import Checkbox from "./Checkbox";
import debounceField from "./debounceField";
import FileField from "./File";
import Group from "./Group";
import ImageField from "./Image";
import ImageOldField from "./ImageOld";
import RadioBox from "./RadioBox"
import RadioBoxLegend from "./RadioBoxLegend";
import Select from "./Select";
import Text from "./Text";
import Textarea from "./Textarea";


const DebouncedText = debounceField(Text)
const DebouncedTextarea = debounceField(Textarea)
let browser = function() {
  
  // Internet Explorer 6-11
  let isIE = /*@cc_on!@*/false || !!document.documentMode;
   // Edge 20+
  let isEdge = !isIE && !!window.StyleMedia;

  return isEdge ;
};

export const FormField = ({ type, ...rest }) => {
  const mode =  rest['mode']

  let Constructor

  switch (type) {

    //case "number" : Constructor = NumberInput; break
    //case "integer" : Constructor = Integer; break

    case "checkbox": Constructor = Checkbox; break
    case "group": Constructor = Group; break
    case "select": Constructor = Select; break
    case "file": Constructor = FileField; break
    case "image":
      browser() ? Constructor = ImageOldField : Constructor = ImageField; break
    case "radiobox": mode === 'legend' ? Constructor = RadioBoxLegend : Constructor = RadioBox; break
    case "textarea":
      Constructor = rest.debounced ? DebouncedTextarea : Textarea
      delete rest.debounced
      break
    default:
      if (typeof type === "function") {
        return <FormControl componentClass={type} {...rest} />
      } else {
        Constructor = rest.debounced ? DebouncedText : Text
        rest.type = type
        delete rest.debounced
      }

  }

  if(rest.placeholder && rest.required) {
    if(!rest.isFilled){
      rest.placeholder += ' *'
    }
    
  }

  if (type !== "select" && type !== "radiobox" && type !== "tree-select" && type !== "checkbox" && type !== "cascader-select") {
    delete rest.required
    delete rest.children
  }

  return <Constructor {...rest} />
}

FormField.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}

export default FormField

import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React from "react";

export default function debounceField(Component, delay = 300) {

  class DebouncedField extends React.Component {

    constructor(props) {

      super(props)

      this.state = { value: "" }

      this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {

      const value = e && e.target ? e.target.value : e

      this.setState({ value })

      if (this.onChange) this.onChange(value, e)
    }

    componentDidMount() {

      const { value, onChange } = this.props

      if (value) this.setState({ value })

      if (onChange) this.onChange = debounce(onChange, delay)
    }

    componentDidUpdate(prevProps) {

      const { onChange, value } = this.props

      if (prevProps.onChange !== onChange) {
        if (onChange) this.onChange = debounce(onChange, delay)
        else this.onChange = null
      }

      if (prevProps.value !== value && value !== this.state.value) {
        this.setState({ value })
      }
    }

    render() {
      return <Component {...this.props} value={this.state.value} onChange={this.handleChange} />
    }

  }

  DebouncedField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
  }

  return DebouncedField
}

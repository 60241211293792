import React from "react";

const SvgApply = props => (
  <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
    <path id="Combined-Shape" fill={props.color1} className="st0" d="M16,22.4c0.4,0,0.8,0.4,0.8,0.8S16.4,24,16,24H0.8C0.4,24,0,23.6,0,23.2V0.8
      C0,0.4,0.4,0,0.8,0h16c0.4,0,0.8,0.4,0.8,0.8V8c0,0.4-0.4,0.8-0.8,0.8S16,8.4,16,8V1.6H1.6v20.8H16z M4.8,5.6C4.4,5.6,4,5.2,4,4.8
      C4,4.4,4.4,4,4.8,4H8c0.4,0,0.8,0.4,0.8,0.8S8.4,5.6,8,5.6C8,5.6,4.8,5.6,4.8,5.6z M13.6,9.6c0,0.4-0.4,0.8-0.8,0.8h-8
      C4.4,10.4,4,10,4,9.6s0.4-0.8,0.8-0.8h8C13.2,8.8,13.6,9.2,13.6,9.6z M9.6,13.6c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8H4.8
      c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8H9.6z M9.6,18.4c0.4,0,0.8,0.4,0.8,0.8S10,20,9.6,20H4.8C4.4,20,4,19.6,4,19.2
      s0.4-0.8,0.8-0.8C4.8,18.4,9.6,18.4,9.6,18.4z M15.7,19.2l6.4-6.4L20,10.7l-6.4,6.4v2.1H15.7z M23.8,12.2c0.3,0.3,0.3,0.8,0,1.1
      l-7.2,7.2c-0.1,0.2-0.4,0.2-0.6,0.2h-3.2c-0.4,0-0.8-0.4-0.8-0.8v-3.2c0-0.2,0.1-0.4,0.2-0.6L19.4,9c0.3-0.3,0.8-0.3,1.1,0
      L23.8,12.2z"
    />
  </svg>

);

export default SvgApply;
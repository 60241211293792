import PropTypes from "prop-types";
import React from "react";
import { Field } from "react-final-form";
import FormField from "../FormField";
import { composeValidators, checkboxReq, required as req } from "../FormField/rules";
import GroupField from "../GroupField";

export * from "../FormField/rules";

const componentPropTypes = {
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  input: PropTypes.object,
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  className: PropTypes.string,
  inline: PropTypes.bool,
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  help: PropTypes.string
}
/* 
  <FinalField>
    <FormField type="date"/>
  </FinalField>
*/


class Component extends React.Component {

  static propTypes = componentPropTypes

  getValidationState() {

    const { touched, error, warning, submitError } = this.props.meta

    if (!touched) return null
    else if (error || submitError) return "error"
    else if (warning) return "warning"
    else return null // "success"
  }

  setFormControl() {

    const { type, input, children, label, readOnly, isRequired, langs, ...rest } = this.props

    for (const n in componentPropTypes) delete rest[n]

    const props = { type, label, readOnly, required: isRequired, langs, ...input, ...rest }

    if (type === "checkbox" || type === "switch") props.label = label

    let field

    if (!type || typeof type === "string") field = <FormField {...props}>{children}</FormField>
    else if (typeof type === "function") {
      delete props.type
      field = React.createElement(type, props, children)
    }

    return field
  }

  render() {

    const { meta, style, type, label, langs, className, inline, labelWidth, readOnly, required, isRequired, help } = this.props

    const props = {
      message: (meta && meta.touched && (meta.error || meta.submitError || meta.warning)) || null,
      level: meta && this.getValidationState(meta),
      style: {
        ...(inline ? { alignItems: readOnly ? "baseline" : "center" } : null),
        ...style
      },
      className,
      labelWidth,
      inline,
      readOnly,
      required,
      isRequired,
      help,
      langs
    }

    if (type !== "checkbox" && type !== "switch" && label) props.label = label

    return (
      <GroupField {...props}>
        {this.setFormControl()}
      </GroupField>
    )
  }

}

const FinalField = props => {

  const { required, validate, type, placeholder, isFilled, value, label, ...rest } = props
  const defaultValue = isFilled === true ? value || placeholder : value
  let validator = !validate && required ? req : validate
  validator = type === 'checkbox' ? checkboxReq : validator

  if (Array.isArray(validator)) {
    if (validator.length) {
      validator = composeValidators(validator);
    } else {
      validator = null
    }
  }

  return (
    <Field
      {...rest}
      defaultValue={defaultValue}
      validate={validator}
      type={type}
      component={Component}
      isRequired={required}
      required={required}
      label={type !== "file" ? label : ""}
      placeholder={props.langs.disableTitles === "false" && type !== "file" ? placeholder : placeholder || label}
    />
  )
}

FinalField.propTypes = {
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  readOnly: PropTypes.bool,
  required: PropTypes.any
}

export default FinalField

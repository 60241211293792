import PropTypes from "prop-types";
import React from "react";
import { FormCheck as BsCheckbox } from "react-bootstrap";


const types = {
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  style: PropTypes.object,
  required: PropTypes.bool
}

class Checkbox extends React.Component {

  static propTypes = types

  constructor(props) {

    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {

    const { value, onChange } = this.props

    if (onChange) onChange(!value)
  }

  render() {

    const { readOnly, required, value, label, intl, style, ...rest } = this.props

    const labelIntl = label

    const checked = Boolean(value)

    if (readOnly) {

      /* eslint-disable-next-line */
      for (const n in BsCheckbox.propTypes) delete rest[n]

      if (checked) return <span style={style} {...rest}>{"\u2713 " + labelIntl}</span>
      else return <span style={{ textDecoration: "line-through", ...style }} {...rest}>{labelIntl}</span>
    }

    const labelHTML = (<span> {labelIntl} {required && !readOnly ? <span style={{ color: "#EC5F78" }}> *</span> : ""}</span>)

    return (
      <BsCheckbox
        inline
        checked={checked}
        label={labelHTML}
        style={style}
        {...rest}
        onChange={this.handleChange}
        className="juloa-checkbox"
      />
    )
  }

}

export default Checkbox

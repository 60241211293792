import React from 'react';

export const FilesList = ({ files, removeFile, isImage }) => Object.keys(files).map(filename => {
  return (
    <div key={filename} className='fileUploaderItem imageOld'>
      <span>
        <img
          src={`./img/file-${getPicture(files[filename].mimeType)}`}
          height={20}
          width={20}
          alt="Mimetype miniature"
        />
        {filename}
      </span>
      <div className="ProgressBar">
        <div className="Progress" style={{ width: files[filename].percentage + "%" }} />
      </div>
      {files[filename].percentage === 100 && <div className="removeFile" onClick={removeFile(filename)}>&times;</div>}
    </div>
  )
});

export const getPicture = mimeType => {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/png':
      return 'image.svg';
    case 'application/pdf':
      return 'pdf.svg'
    default:
      return 'solid.svg';
  }
}

export default FilesList;
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const FormSkeleton = () => {
  return (
    <div className="sl-form" style={{ textAlign: 'left' }}>
      <Skeleton width={140} height={20} />
      <hr />
      <Skeleton width={340} height={10} />
      <div className="group-startup">
        <div className="section-title" style={{ textAlign: 'left' }}>
          <Skeleton width={160} height={20} />
        </div>
      </div>
      <Container>
        <Row className="idcard" style={{ marginBottom: '7px' }}>
          <Col md={3}>
            <div className="form-group">
              <div className="form-group-container">
                <Skeleton className="logo-container" height={178} />
              </div>
            </div>
          </Col>
          <Col md={9}>
            <div className="form-group">
              <div className="form-group-container">
                <Skeleton height={45} />
              </div>
            </div>
            <div className="form-group">
              <div className="form-group-container">
                <Skeleton height={45} />
              </div>
            </div>
            <div className="form-group">
              <div className="form-group-container">
                <Skeleton height={45} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="form-group">
              <div className="form-group-container">
                <Skeleton height={45} />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form-group">
              <div className="form-group-container">
                <Skeleton height={45} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FormSkeleton

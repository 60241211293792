import * as rules from "./rules";

export function getMessage(key, ...rest) {
  return window.langs[key]
}

export const required = (value, allValues) => {  
  return rules.required(value) ? null : getMessage("FIELD_IS_REQUIRED_ERROR_MSG")
}

export const checkboxReq = (value, allValues) => {  
  return value === true ? null : getMessage("FIELD_IS_REQUIRED_ERROR_MSG")
}

export const minLength = min => {

  const validate = rules.minLength(min)

  return (value, allValues) => {
    return validate(value) ? null : getMessage("NOT_ENOUGH_CHARACTERS_ERROR_MSG", { value: min })
  }

}

export const maxLength = max => {

  const validate = rules.maxLength(max)

  return (value, allValues) => {
    return validate(value) ? null : getMessage("TOO_MANY_CHARACTERS_ERROR_MSG")
  }

}

export const composeValidators = (validators) => value => {
 return validators.reduce((error, validator) => { return error || validator(value) }, undefined)
}

export const instance = Construct => {

  const validate = rules.instance(Construct)

  return (value, allValues) => {
    return validate(value) ? null : getMessage("instanceInvalid", {
      expected: Construct.name,
      instance: value == null ? "null" : value.constructor.name
    })
  }
}

export const type = typeName => {

  const validate = rules.type(typeName)

  return (value, allValues) => {
    return validate(value) ? null : getMessage("typeInvalid", {
      expected: typeName,
      type: value == null ? "null" : typeof value
    })
  }
}


export const slug = (value, allValues) => {
  return rules.slug(value) ? null : getMessage("slugInvalid")
}

export const email = (value, allValues) => {
  return !value || rules.email(value) ? null : getMessage("INCORRECT_EMAIL_ERROR_MSG")
}

export const url = (value, allValues) => {
  return !value || rules.url(value) ? null : getMessage("WEBSITE_INVALID_ERROR_MSG")
}

export const id = (value, allValues) => {
  return rules.id(value) ? null : getMessage("idInvalid")
}

export const alphaNum = car => {

  const validate = rules.alphaNum(car)

  return (value, allValues) => {
    return validate(value) ? null : getMessage("errorAlphaNum", { value: car })
  }
}

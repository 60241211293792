import React from "react"
import PropTypes from "prop-types"
import { FormControl } from "react-bootstrap"

import createCounterText from "../createCounterText"

const CounterFormField = createCounterText( (props) => <FormControl { ...props } /> )

class TextArea extends React.Component {

  constructor(props) {

    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {

    const { onChange } = this.props

    if (onChange) onChange(e.target.value)
  }

  render() {

    const { placeholder, value, readOnly, resize, style, langs, defaultValue, ...rest } = this.props

    if (readOnly) {

      const readOnlyStyle = {
        wordWrap : "break-word",
        whiteSpace : "pre-wrap",
        ...style
      }

      return (
        <FormControl.Static style={ readOnlyStyle } { ...rest }>
          { value }
        </FormControl.Static>
      )

    }

    let cssResize

    if (typeof resize === "boolean") cssResize = resize ? "both" : "none"
    else cssResize = resize

    delete rest.onChange;

    let Component = FormControl;

    if (rest.limit) {
      Component = CounterFormField
    }
    
    return (
      <Component
        placeholder={ placeholder }
        characters={langs.CHARACTERS_MAX}
        value={ typeof value === 'string' ? value : defaultValue }
        as="textarea"
        style={ { resize : cssResize, ...style } }
        onChange={ this.handleChange }
        { ...rest }
      />
    )
  }
}

TextArea.propTypes = {
  placeholder : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  readOnly : PropTypes.bool,
  value : PropTypes.string,
  resize : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange : PropTypes.func,
  style : PropTypes.object
}

export default TextArea

import Chance from "chance";
import PropTypes from "prop-types";
import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import createCounterText from "../createCounterText";
import FormField from "../index";



const chance = new Chance()

const MaskFormControl = (ref, props) => (
  <input className="form-control" ref={ref} {...props} />
)

const CounterFormField = createCounterText((props) => <FormField {...props} />)

const types = {
  input: PropTypes.object,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  addon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  addonRight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dropdown: PropTypes.object,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  limit: PropTypes.number
}

class Text extends React.Component {

  static propTypes = types

  constructor(props) {

    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {

    const { onChange } = this.props

    if (onChange) onChange(e.target.value) // marche po
  }

  setPlaceholder() {

    const { placeholder } = this.props

    return placeholder

  }

  removePropTypes(rest) {

    for (const n in types) delete rest[n]

    return rest
  }

  createReadOnlyField() {

    const { value, type, ...rest } = this.props

    let content = value

    if (type === "password") content = value.split("").map(() => "*")

    return <FormControl.Static {...this.removePropTypes(rest)}>{content}</FormControl.Static>

  }

  wrapComponent(formControl) {

    const { addon, addonRight, dropdown } = this.props

    return (
      <InputGroup>
        {addon && <InputGroup.Addon>{addon}</InputGroup.Addon>}
        {formControl}
        {dropdown && (
          React.cloneElement(dropdown, {
            componentClass: InputGroup.Button,
            id: dropdown.props.id || chance.guid()
          })
        )}
        {addonRight && <InputGroup.Addon>{addonRight}</InputGroup.Addon>}
      </InputGroup>
    )

  }

  render() {

    const { addon, addonRight, dropdown, readOnly, value, type, mask, limit, langs, ...rest } = this.props

    if (readOnly) return this.createReadOnlyField()

    let props = {
      placeholder: this.setPlaceholder(),
      onChange: this.handleChange,
      type,
      ...this.removePropTypes(rest)
    }

    let formControl

    if (mask) {

      formControl = (
        <MaskedInput
          mask={mask}
          render={MaskFormControl}
          {...props}
        />
      )

    } else formControl = <FormControl {...props} />

    if (limit) {

      // redefine props
      // onChange from FormField
      // type = "text"
      const props = {
        ...this.props,
        placeholder: this.setPlaceholder(),
        type: "text"
      }

      formControl = (
        <CounterFormField
          limit={limit}
          characters={langs.CHARACTERS_MAX}
          // mask={ mask }
          // render={ MaskFormControl }
          {...props}
        />
      )
    }

    if (addon || dropdown || addonRight) return this.wrapComponent(formControl)
    else return formControl
  }

}


export default Text

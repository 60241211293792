import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import FilesList from '../File/FilesList';


/* eslint-disable react/jsx-no-bind */

class ImageField extends React.PureComponent {

  constructor(props) {

    super(props)

    this.input = React.createRef();

    this.state = { tmpValue: null, keepSelection: true, crop: { aspect: this.props.ratio }, files: {} }
  }
  
  handleChange = evt => {
    const files = evt.target.files;
    const {langs} = this.props;
    const fileName = files.item(0).name;
    const allowed_extensions = ['jpg', 'jpeg','png','gif'];
    let file_extension = fileName.split('.').pop();
    if(allowed_extensions.indexOf(file_extension) === -1){
      alert(`${file_extension} ${langs.extensions}`);
      return false
    }

    if (files.length > 0) {
      Object.keys(this.state.files).map(e => this.state.files[e]).forEach(file => this.deleteFile(file.filename));
      this.setState({
        tmpValue: files.item(0),
        image: null,
        value: null,
        crop: { aspect: this.props.ratio, x: 0, y: 0, width: 0, height: 0 },
        files: {},
      });
    }
  }

  handleHide = () => {
    this.input.current.value = "";
    this.setState({ tmpValue: null, image: null });
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  /**
   * @param {File} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  getCroppedImg = async (image, fileType, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    // As a blob
    return new Promise(resolve =>
      canvas.toBlob(blob => resolve(new File([blob], fileName)), fileType));
  }


  saveCropImage = async () => {
    const { crop, image, tmpValue } = this.state;

    if (crop.width === undefined || crop.width === 0) {
      return
    }

    const img = await this.getCroppedImg(image, tmpValue.type, crop, tmpValue.name);

    const uploadedFile = await this.sendFile(img);

    this.props.onChange(uploadedFile.filename);
    this.setState({
      ...this.state,
      tmpValue: null,
      image: null,
      value: img,
      files: {
        ...this.state.files,
        [tmpValue.name]: {
          ...this.state.files[tmpValue.name],
          mimeType: uploadedFile.type,
          filename: uploadedFile.filename,
        },
      },
    });
  }

  onImageLoaded = image => {
    if (this.state.image == null) {
      let width;
      let height;
      

      const imageRatio = image.width / image.height;

      if (imageRatio > this.state.crop.aspect) {
        height = image.height;
        width = Math.round(image.height * this.state.crop.aspect);
      } else {
        width = image.width;
        height = Math.round(image.width / this.state.crop.aspect);
      }

      const crop = { ...this.state.crop };
      crop.width = width;
      crop.height = height;
      crop.x = Math.round((image.width - width) / 2);
      crop.y = Math.round((image.height - height) / 2);
      this.setState({ image, crop });
    }
  }

  sendFile = async file => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.files };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ files: copy });
        } else {
          console.log("not computable");
        }
      });

      req.upload.addEventListener("load", event => {
        const { files } = this.state;
        const copy = { ...files };

        copy[file.name] = { state: "done", percentage: 100 };
        if (files[file.name] && files[file.name].isToRemove) {
          this.deleteFile(file.name);
          this.props.onChange('');
          this.setState({ value: null });
          return;
        }
        this.setState({ files: copy });
      });

      req.addEventListener("load", function () {
        resolve(JSON.parse(this.response));
      })

      req.upload.addEventListener("error", () => {
        const copy = { ...this.state.files };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ files: copy });
        reject(req.response);
      });

      const url = 'api.php?task=saveFile';
      const formData = new FormData();

      formData.append("file", file);

      req.open("POST", url);
      req.send(formData);
    });
  }


  removeFile = filename => () => {
    const { files: { [filename]: fileToRemove, ...files } } = this.state;
    if (fileToRemove && fileToRemove.state === 'done') {
      this.setState({ ...this.state, files });
      this.deleteFile(fileToRemove.filename);
      this.props.onChange('');
      this.setState({ value: null });
    } else {
      this.setState({
        ...this.state,
        files: {
          [filename]: { ...fileToRemove, isToRemove: true },
          ...files
        },
      });
    }
  }

  deleteFile = async filename => {
    const url = 'api.php?task=deleteFile';
    const formData = new FormData();

    formData.append('filename', filename);
    await fetch(url, { method: 'POST', body: formData });
  }

  render() {
    const {
      onCropChange, onImageLoaded, handleHide,
      saveCropImage, input, handleChange, removeFile,
    } = this;
    const { files, tmpValue, crop, value } = this.state;
    const { editionImg, cancel, save, placeholder } = this.props;
    
    let modal = null
    if (tmpValue) {
      const url = URL.createObjectURL(tmpValue);
      
      modal = (
        <Modal show={true} onHide={handleHide}>
          <Modal.Header closeButton>
            <Modal.Title>{editionImg}</Modal.Title>
          </Modal.Header>
          
          <Modal.Body style={{ margin: "0 auto" }}>
            <ReactCrop src={url} onChange={onCropChange} crop={crop} onImageLoaded={onImageLoaded} />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleHide}>{cancel}</Button>
            <Button variant="primary" onClick={saveCropImage}>{save}</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    if (value) {
      const url = URL.createObjectURL(value)
      
      return (
        <React.Fragment>
          {modal}
          <div
            className="image-uploader logo-completed"
            style={{ backgroundImage: 'url(' + url + ')' }}
            key="logoCompleted"
          >
            <input
              ref={input}
              title=""
              accept="image/x-png,image/gif,image/jpeg"
              type="file"
              className="file-upload"
              onChange={handleChange}
            />
          </div>
          <FilesList isImage={true} files={files} removeFile={removeFile} />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {modal}
        <div className="image-uploader">
          <span>+</span>
          <div className="image-uploader-title">{placeholder}</div>
          <input
            title=""
            ref={input}
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            className="file-upload"
            onChange={handleChange}
          />
        </div>
      </React.Fragment>
    );
  }
}

ImageField.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  ratio: PropTypes.number
}

ImageField.defaultProps = {
  ratio: 1
}

export default ImageField
